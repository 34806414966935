import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/getFirstPossibleRoute'

const studentTimetableChildRoutes = [
  {
    path: '',
    name: 'student-timetable-view' as const,
    component: () =>
      import('@/views/AdminPanel/Timetable/Student/StudentTimetableView.vue'),
    meta: {
      title: 'Student Timetable',
      tab: 'academics',
      permissions: ['permissions.view_students_timetable']
    }
  },
  {
    path: ':lessonId',
    name: 'student-timetable-activity' as const,
    component: () => import('@/views/TeacherPanel/TimetableDetails.vue'),
    meta: {
      title: 'Student Timetable',
      tab: 'academics',
      permissions: ['activities.view_lesson']
    }
  }
] satisfies Route[]

export const studentTimetableRoutes = [
  {
    path: 'timetable/student',
    name: 'student-timetable' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Student timetable',
      tab: 'academics',
      icon: 'calendar',
      permissions: ['permissions.view_students_timetable']
    },
    redirect: () => getFirstPossibleRoute(studentTimetableChildRoutes),
    children: studentTimetableChildRoutes
  }
] satisfies Route[]
