import { type Route } from '@/types/route'

import { timetableRoutes } from './timetable'
import { getFirstPossibleRoute } from '../../utils/getFirstPossibleRoute'

// Can be removed when BE will confirm they don't keep url's in db
// https://panowie.atlassian.net/browse/RPS-8958
const redirects = [
  {
    path: '/timetables',
    name: 'redirect-timetables' as const,
    meta: {
      tab: 'academics'
    },
    redirect: '/planner/timetables'
  },
  {
    path: '/timetables/*',
    name: 'redirect-nested-timetables' as const,
    meta: {
      tab: 'academics'
    },
    redirect: route => {
      return { path: `/planner${route.path}` }
    }
  }
] satisfies Route[]

const roomsChildrenRoutes = [
  {
    path: '',
    name: 'rooms-list' as const,
    component: () => import('@/views/Planner/RoomList/RoomListView.vue'),
    meta: {
      title: 'Rooms',
      tab: 'administration',
      permissions: ['rooms.view_room']
    }
  },
  {
    path: 'add',
    name: 'room-add' as const,
    component: () => import('@/views/Planner/RoomForm/index.vue'),
    meta: {
      title: 'Create room',
      tab: 'administration',
      permissions: ['rooms.view_room']
    }
  },
  {
    path: ':roomId',
    name: 'room-details' as const,
    component: () => import('@/views/Planner/RoomForm/index.vue'),
    meta: {
      title: 'Edit room',
      tab: 'administration',
      permissions: ['rooms.view_room']
    }
  }
] satisfies Route[]

const coursesChildrenRoutes = [
  {
    path: '',
    name: 'courses-list' as const,
    component: () => import('@/views/Planner/CourseList/CourseListView.vue'),
    meta: {
      title: 'Courses',
      tab: 'administration',
      permissions: ['courses.view_course']
    }
  },
  {
    path: 'add',
    name: 'course-create' as const,
    component: () => import('@/views/Planner/CourseForm/index.vue'),
    meta: {
      title: 'Create course',
      tab: 'administration',
      permissions: ['remote_generator.view_generationtask']
    }
  },
  // Hidden for 7.3.0 version
  // {
  //   path: 'import-custom-constraints',
  //   name: 'course-custom-constraints' as const,
  //   component: () =>
  //     import('@/views/Planner/CustomConstraints/CustomConstraints.vue'),
  //   meta: {
  //     title: 'Import custom constraints',
  //     tab: 'administration',
  //     permissions: ['courses.view_course']
  //   }
  // },
  {
    path: ':courseId',
    name: 'course-edit' as const,
    component: () => import('@/views/Planner/CourseForm/index.vue'),
    meta: {
      title: 'Edit course',
      tab: 'administration',
      permissions: ['remote_generator.view_generationtask']
    }
  }
] satisfies Route[]

const generatorTabRoutes = [
  {
    path: 'timetables',
    name: 'timetable-list' as const,
    component: () =>
      import('@/views/Planner/TimetableList/TimetableListView.vue'),
    meta: {
      title: 'Timetables & Boarding Rotas',
      tab: 'administration',
      icon: 'calendar',
      permissions: ['timetable.view_timetable']
    }
  },
  {
    path: 'sections',
    name: 'sections-list' as const,
    component: () => import('@/views/Planner/SectionList/SectionListView.vue'),
    meta: {
      title: 'Sections',
      tab: 'administration',
      icon: 'block',
      permissions: ['sections.view_section']
    }
  },
  {
    path: 'courses',
    name: 'courses-list-tab' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Courses',
      tab: 'administration',
      icon: 'laptop',
      permissions: ['courses.view_course']
    },
    redirect: () => getFirstPossibleRoute(coursesChildrenRoutes),
    children: coursesChildrenRoutes
  },
  {
    path: 'rooms',
    name: 'rooms-list-tab' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Rooms',
      tab: 'administration',
      icon: 'bank',
      permissions: ['rooms.view_room']
    },
    redirect: () => getFirstPossibleRoute(roomsChildrenRoutes),
    children: roomsChildrenRoutes
  }
] satisfies Route[]

const sectionsRoute = [
  {
    path: 'sections/add',
    name: 'add-section' as const,
    component: () => import('@/views/Planner/SectionForm/SectionFormView.vue'),
    meta: {
      title: 'Section create',
      tab: 'administration',
      permissions: ['remote_generator.view_generationtask']
    }
  },
  {
    path: 'sections/:sectionId',
    name: 'edit-section' as const,
    component: () => import('@/views/Planner/SectionForm/SectionFormView.vue'),
    meta: {
      title: 'Section edit',
      tab: 'administration',
      permissions: ['remote_generator.view_generationtask']
    }
  }
] satisfies Route[]

const generatorNotTabRoutes = [
  {
    path: '',
    name: 'generator-tab' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: generatorTabRoutes
    },
    meta: {
      title: 'Generator',
      tab: 'administration'
    },
    redirect: () => getFirstPossibleRoute(generatorTabRoutes),
    children: generatorTabRoutes
  },
  ...redirects,
  ...sectionsRoute,
  ...timetableRoutes
] satisfies Route[]

export const generatorRoutes = [
  {
    path: 'planner',
    name: 'generator-planner' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Generator',
      tab: 'administration',
      icon: 'sliders',
      permissions: ['permissions.can_view_generator']
    },
    redirect: () => getFirstPossibleRoute(generatorNotTabRoutes),
    children: generatorNotTabRoutes
  }
] satisfies Route[]
