import type { Route } from '@/types/route'

import { getFirstPossibleRoute } from './utils/getFirstPossibleRoute'

const dashboardChildrenRoutes = [
  {
    path: '',
    name: 'dashboard-view' as const,
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    meta: {
      tab: 'dashboard',
      title: 'Dashboard'
    },
    redirect: 'tasks',
    children: [
      {
        path: 'tasks',
        name: 'dashboard-tasks' as const,
        component: () => import('@/views/Dashboard/Tasks/Tasks.vue'),
        meta: {
          tab: 'dashboard',
          title: 'Tasks'
        }
      }
    ]
  }
] satisfies Route[]

export const dashboardRoutes = [
  {
    path: 'dashboard',
    name: 'dashboard' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'dashboard',
      title: 'Dashboard'
    },
    redirect: () => getFirstPossibleRoute(dashboardChildrenRoutes),
    children: dashboardChildrenRoutes
  }
] satisfies Route[]
