import type { InjectionKey, Ref } from 'vue'

import type {
  DraftActivitySuggesterTask,
  ImportConflict,
  SuggesterUserSession,
  TimetableImportNotification
} from './types'

export const DraftActivitySuggesterKey: InjectionKey<{
  draftActivitySuggesterNotification: Ref<
    DraftActivitySuggesterTask | undefined
  >
  setDraftActivitySuggesterNotification: () => void
}> = Symbol('DraftActivitySuggester')
export const ImportConflictKey: InjectionKey<Ref<ImportConflict>> =
  Symbol('ImportConflict')
export const MarkAsDeliveredKey: InjectionKey<(id: number) => void> =
  Symbol('MarkAsDelivered')
export const SuggesterUserSessionKey: InjectionKey<Ref<SuggesterUserSession>> =
  Symbol('SuggesterUserSession')
export const TimetableImportNotificationKey: InjectionKey<{
  timetableImportNotification?: Ref<TimetableImportNotification | undefined>
  setTimetableImportNotification: () => void
}> = Symbol('TimetableImportNotification')

export const taeGenerationNotification = {
  start: 'tae-generation-started',
  startFailed: 'tae-generation-start-failed',
  cancel: 'tae-generation-stopped',
  success: 'tae-generation-success',
  applyingInProgress: 'tae-generation-applying-in-progress',
  applied: 'tae-generation-applied',
  applyFailed: 'tae-generation-failed',
  generationFailed: 'tae-generation-failed',
  discarded: 'tae-generation-discarded'
} as const
