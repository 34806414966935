import axios from 'axios'

// eslint-disable-next-line
import Service, { prepareURL, getQuery } from '@/api/service'
import getFormDataFromJSON from '@/functions/formdata'

axios.defaults.baseURL = import.meta.env.VITE_API_URL

/*
    using '...Service('/url/:id/')' in object provides methods:
        fetch
        get
        delete
        put
        save
 */
export const Auth = {
  me() {
    return axios.get('/users/me/')
  },
  remind(payload) {
    return axios.post('/users/auth/reset/', payload)
  },
  login(payload) {
    return axios.post('/users/auth/login/', payload)
  },
  validateCode(payload) {
    return axios.post('/users/auth/login/validate-code/', payload)
  },
  changePassword(payload) {
    return axios.post('/users/auth/set_password/', payload)
  },
  logout() {
    return axios.post('/users/auth/logout/')
  },
  resetPassword(payload) {
    return axios.post('/users/auth/reset/confirm/', payload)
  }
}

export const Avatar = {
  get() {
    const url = '/users/change-avatar/'
    return axios.get(url)
  },
  update(payload) {
    return axios.put(`/users/change-avatar/`, payload)
  }
}

export const Aim = {
  all() {
    return axios.get('/aims/')
  },
  calcGrade: payload => {
    return axios.post('/aims/calc-grade/', payload)
  }
}

export const EnrollmentReport = {
  fetch(params) {
    const url = '/activities/enrollments/'
    return axios.get(url, {
      responseType: 'blob',
      params
    })
  }
}

export const AssignmentReport = {
  fetch(params) {
    const url = '/assignments/reports/'
    return axios.get(url, {
      responseType: 'blob',
      params
    })
  }
}

export const AcademicAttendance = {
  ...Service('/attendances/:id/')
}

export const AcademicAttendanceReport = {
  fetch(params) {
    const url = '/attendances/reports/'
    return axios.get(url, {
      responseType: 'blob',
      params
    })
  }
}

export const Student = {
  ...Service('/students/:id/'),
  fetchAvailableFilters(params) {
    const url = '/students/available-filters'
    const payload = { params: { ...getQuery(), ...params } }

    return axios.get(url, payload)
  },
  downloadReportCard(studentId, params) {
    const url = `/students/${studentId}/report-card/`
    return axios.get(url, {
      metadata: {
        disable403Redirection: true
      },
      params,
      responseType: 'blob'
    })
  },
  adminChangePassword: payload => {
    const studentId = payload.studentId
    return axios.post(`/students/${studentId}/set-password/`, payload)
  },
  toggleActive: urlParams => {
    const url = '/students/:id/toggle-active/'
    return axios.post(prepareURL(url, urlParams))
  },
  toggleVaccination: studentId => {
    const url = `/students/${studentId}/toggle-vaccination/`
    return axios.patch(url)
  },
  changeBloodType(studentId, bloodType) {
    const url = `/students/${studentId}/blood-type/`
    return axios.patch(url, { blood_type: bloodType })
  },
  currentLocations(payload) {
    const url = '/students/current-locations/'
    return axios.get(url, payload)
  },
  updateExamRecommendations: payload => {
    const studentId = payload.studentId
    return axios.patch(`/students/${studentId}/exam-recommendations/`, payload)
  },
  fetchAccessArrangementsChoices() {
    return axios.get('/students/access-arrangements-choices/')
  }
}

export const StudentAim = {
  getForStudent(studentId) {
    return axios.get(`/aims/for-student/${studentId}/`)
  },
  getAimsInCurrentYear() {
    return axios.get(`/aims/for-student/`)
  }
}

export const DraftMedication = {
  ...Service('/student-applications/medications/:id/'),
  ignore: urlParams => {
    const url = '/student-applications/medications/:id/ignore/'
    return axios.post(prepareURL(url, urlParams))
  }
}

export const Assignment = {
  ...Service('/assignments/:id/'),
  fetchAsignmentsList(params) {
    const url = '/assignments/'
    return axios.get(url, params)
  },
  fetchAssignmentTypes(section) {
    return axios.get('/assignments/types/', { params: section })
  }
}

export const StudentAssignmentItem = {
  ...Service('/assignments/items/:id/')
}

export const GradeLevel = {
  ...Service('/curriculum/grade-levels/:id/')
}

export const CoursePlanner = {
  ...Service('/courses/planner/:id/'),
  toggleLatestVersion(courseId) {
    const url = `/courses/planner/${courseId}/toggle-latest-version/`
    return axios.post(url)
  }
}

export const CourseEnrollment = {
  fetch(params) {
    const url = '/courses/enrollments/'
    return axios.get(url, { params })
  }
}

export const Course = {
  ...Service('/courses/:id/'),
  all: (payload = {}) => {
    const params = {
      page_size: 'max',
      ...payload
    }
    return axios.get('/courses/', { params })
  },
  students({ courseId, quarterId, semesterId }) {
    const params = {
      quarter: quarterId,
      semester: semesterId
    }
    return axios.get(`/courses/${courseId}/students/`, { params })
  },
  updateDescription(id, payload) {
    const url = `/courses/${id}/update-description/`
    return axios.patch(url, payload)
  }
}

export const Quarter = {
  ...Service('/periods/quarters/:id/'),
  getQuartersForStudent(studentId) {
    return axios.get('/periods/quarters/', {
      student: studentId,
      page_size: 9999
    })
  }
}

export const Semester = Service('/periods/semesters/:id/')

export const AccGrade = {
  ...Service('/grades/acc/:id/'),
  customGrades(course) {
    const url = '/grades/custom-grades/'
    const params = { course }
    return axios.get(url, { params })
  },
  idpTable(params) {
    const url = `/grades/acc/idp-table/`
    return axios.get(url, {
      params
    })
  },
  downloadCsv(params) {
    return axios.get('/grades/acc/csv/', {
      params,
      responseType: 'blob'
    })
  },
  downloadCsvForCurrentQuarter(params) {
    return axios.get('/grades/acc/csv-for-current-quarter/', {
      params,
      responseType: 'blob'
    })
  }
}

export const SchoolYear = Service('/periods/years/:id/')

export const Merit = {
  ...Service('/merits/:id/'),
  confirm(meritId, payload) {
    return axios.put(`/merits/${meritId}/confirm/`, payload)
  },
  reject(meritId, params) {
    return axios.put(`/merits/${meritId}/reject/`, params)
  },
  deescalate(meritId) {
    return axios.post(`/merits/${meritId}/deescalate/`)
  },
  behaviours() {
    return axios.get(`/merits/behaviours/`)
  },
  previewSanction(meritId) {
    return axios.get(`/merits/${meritId}/preview-sanction/`)
  },
  statistics(params = {}) {
    const url = '/merits/statistics/'
    return axios.get(url, { params })
  },
  filterOptions(params = {}) {
    const url = '/merits/filter-options/'
    return axios.get(url, { params })
  }
}

export const MeritsExchange = {
  fetch(params) {
    const url = '/merits-exchange/'

    return axios.get(url, params)
  },
  availableFilters(params) {
    const url = '/merits-exchange/available-filters/'

    return axios.get(url, { params })
  },
  availableStudents(meritId) {
    const url = `/merits-exchange/${meritId}/available-students/`
    return axios.get(url)
  },
  approve(meritId, params = {}) {
    const url = `/merits-exchange/${meritId}/approve/`
    return axios.post(url, params)
  },
  reject(meritId, decisionComment) {
    const url = `/merits-exchange/${meritId}/reject/`
    const params = { decision_comment: decisionComment }
    return axios.post(url, params)
  },
  cancel(meritId, decisionComment) {
    const url = `/merits-exchange/${meritId}/cancel/`
    const params = { decision_comment: decisionComment }
    return axios.post(url, params)
  }
}

export const MeritItem = Service('/merits/items/:id/')

export const WeeklyComment = {
  ...Service('/courses/weekly-comments/:id/'),
  downloadReport(params) {
    return axios.get('/courses/weekly-comments/download/', {
      params,
      responseType: 'blob'
    })
  },
  downloadCsv(params) {
    return axios.get('/courses/weekly-comments/csv/', {
      params,
      responseType: 'blob'
    })
  },
  downloadPdf(params) {
    const url = `/courses/weekly-comments/pdf/`
    return axios.get(url, {
      params,
      responseType: 'blob'
    })
  }
}

export const Department = Service('/departments/:id/')

export const Room = {
  ...Service('/rooms/:id/'),
  importRooms: payload => {
    const formData = getFormDataFromJSON(payload)
    const defaultSave = Service('/planner/activities/update-activities-rooms/')[
      'save'
    ]
    return defaultSave(formData, { 'content-type': 'multipart/form-data' })
  },
  roomWithOccupation(params) {
    return axios.get(`/rooms/with-occupation/`, { params })
  }
}

export const Country = Service('/core/countries/:id/')

export const CourseFamily = Service('/courses-meta/families/:id/')

export const CourseSubject = Service('/courses-meta/subjects/:id/')

export const CourseIntelligence = Service('/courses-meta/intelligences/:id/')

export const CourseGroup = Service('/courses-meta/groups/:id/')

export const CourseLevel = Service('/courses-meta/levels/:id/')

export const CourseType = Service('/courses-meta/types/:id/')

export const TimetableLog = {
  ...Service('/planner/logs/:id/')
}

export const DraftActivity = {
  ...Service('/planner/activities/:id/'),
  count(params) {
    const url = '/planner/activities/count/'
    return axios.get(url, { params })
  },
  hours() {
    const url = '/planner/activities/hours/'
    return axios.get(url)
  },
  days() {
    const url = '/planner/activities/days/'
    return axios.get(url)
  },
  roomPreferences(activityId) {
    const url = `/planner/activities/${activityId}/rooms-preferences/`
    return axios.get(url)
  }
}

export const Section = {
  ...Service('/planner/sections/:id/'),
  sectionToEnroll(params) {
    const url = '/planner/sections/section-to-enroll/'
    return axios.get(url, { params })
  },
  allowedStudents(sectionId) {
    const url = `/planner/sections/${sectionId}/allowed-students/`
    return axios.get(url)
  },
  totalDuration(params) {
    const url = `/planner/sections/total-duration/`
    return axios.get(url, { params })
  },
  usages(sectionId) {
    const url = `/planner/sections/${sectionId}/usages/`
    return axios.get(url)
  }
}

export const Conflicts = {
  fetch({ day, timetableId, params }) {
    return axios.get(`/planner/conflicts/${timetableId}/${day}/`, {
      params
    })
  }
}

export const ActivityMove = {
  fetch(activityId) {
    return axios.get(
      `/planner/potential-optimizations/activities/${activityId}/moves/`
    )
  }
}

export const Timetable = {
  ...Service('/timetable/:id/'),
  collectPotentialOptimizations(timetableId) {
    const url = `/timetable/${timetableId}/collect-potential-optimizations/`
    return axios.post(url)
  },
  stats(timetableId) {
    const url = `/timetable/${timetableId}/stats/`
    return axios.get(url)
  },
  preview(timetableId, payload) {
    const url = `/timetable/${timetableId}/preview/`
    return axios.post(url, payload)
  },
  consistency(timetableId) {
    const url = `/timetable/${timetableId}/consistency/`
    return axios.get(url)
  },
  togglePreviewForTeachers(timetableId) {
    return axios.post(`timetable/${timetableId}/toggle-preview-for-teachers/`)
  },
  freeSlots(timetableId, params) {
    const url = `/timetable/${timetableId}/free-slots/`
    return axios.get(url, { params })
  },
  busyInSlots(timetableId, params) {
    const url = `/timetable/${timetableId}/busy-in-slot/`
    return axios.post(url, params)
  },
  currentLessons() {
    const url = '/timetable/current-lessons/'
    return axios.get(url)
  },
  copyTalentEnrichment(payload) {
    const url = '/timetable/copy-talent-enrichment/'
    return axios.post(url, payload)
  },
  fetchFilterOptions(timetableId) {
    const url = `/timetable/${timetableId}/filter-options/`
    return axios.get(url)
  }
}

export const Report = {
  downloadTeachersActivities: timetableId => {
    const url = '/planner/activities/export-teacher-activities/'
    const params = { timetable: timetableId }
    return axios.get(url, { params, responseType: 'blob' })
  },
  downloadStudentsActivities: timetableId => {
    const url = '/planner/activities/export-student-activities/'
    const params = { timetable: timetableId }
    return axios.get(
      url,
      { params },
      {
        responseType: 'blob'
      }
    )
  },
  downloadClassesAfter16: timetableId => {
    const url = '/planner/activities/export-activities-after-hour/'
    const params = { timetable: timetableId, hour: 8 }
    return axios.get(
      url,
      { params },
      {
        responseType: 'blob'
      }
    )
  },
  downloadAllClassesPerSlots: timetableId => {
    const url = '/planner/activities/export-activities-per-slot/'
    const params = { timetable: timetableId }
    return axios.get(
      url,
      { params },
      {
        responseType: 'blob'
      }
    )
  },
  downloadStudentEnrollments(quarterId) {
    const url = '/planner/sections/export-students-enrollments/'
    const params = { quarter: quarterId }
    return axios.get(
      url,
      {
        params
      },
      {
        responseType: 'blob'
      }
    )
  },
  downloadTeacherEnrollments(quarterId) {
    const url = '/planner/sections/export-teachers-enrolments/'
    const params = { quarter: quarterId }
    return axios.get(
      url,
      {
        params
      },
      {
        responseType: 'blob'
      }
    )
  },
  downloadStudentsInSections(quarterId) {
    const url = '/planner/sections/export-students-in-sections/'
    const params = { quarter: quarterId }
    return axios.get(
      url,
      {
        params
      },
      {
        responseType: 'blob'
      }
    )
  }
}

export const GenerationTask = {
  ...Service('/planner/generator/tasks/:id/'),
  stopGenerator(taskId) {
    const url = `/planner/generator/tasks/${taskId}/stop/`
    return axios.post(url)
  },
  stats(taskId) {
    const url = `/planner/generator/tasks/${taskId}/stats/`
    return axios.get(url)
  },
  fetchCosts() {
    const url = `/planner/generator/tasks/costs/`
    return axios.get(url)
  }
}

export const PotentialOptimization = {
  ...Service('/planner/potential-optimizations/:id/'),
  applyOptimizations(timetableId, optimizations) {
    return axios.put(
      `/planner/potential-optimizations/${timetableId}/apply-potential-optimizations/`,
      {
        selected_optimizations: optimizations
      }
    )
  }
}

export const IdpSectionEnrollment = {
  ...Service('/planner/students-idp/enroll-to-section/:id/')
}

export const SourceIdpPlan = {
  getByid(id) {
    const url = `/timetable/source-idp/${id}/`
    return axios.get(url)
  },
  get({ timetableId, studentId }) {
    const url = `/timetable/source-idp/${studentId}/${timetableId}/`
    return axios.get(url)
  }
}

export const IdpPlan = {
  ...Service('/planner/students-idp/:id/'),
  courses() {
    return axios.get('/planner/students-idp/courses/')
  },
  saveDraft(payload) {
    const url = `/planner/students-idp/save-draft/`
    return axios.post(url, payload)
  },
  updateDraft(idpPlanId, payload) {
    const url = `/planner/students-idp/${idpPlanId}/update-draft/`
    return axios.put(url, payload)
  },
  cancelApprovalRequest(idpPlanId, payload) {
    const url = `/planner/students-idp/${idpPlanId}/cancel-approval-request/`
    return axios.put(url, payload)
  },
  exams() {
    return axios.get('/planner/students-idp/exams/')
  },
  statuses() {
    return axios.get('/planner/students-idp/statuses/')
  },
  lastApproved({ student, year }) {
    const params = {
      student,
      year
    }
    return axios.get(`/planner/students-idp/last-approved/`, { params })
  },
  approve(idpPlanId) {
    return axios.post(`/planner/students-idp/${idpPlanId}/approve/`)
  },
  reject(idpPlanId, payload) {
    return axios.post(`/planner/students-idp/${idpPlanId}/reject/`, payload)
  },
  downloadPdf(idpPlanId) {
    return axios.get(`/planner/students-idp/${idpPlanId}/pdf/`, {
      responseType: 'blob'
    })
  },
  suggestions(params) {
    const url = `/planner/students-idp/suggestions/`
    return axios.post(url, params)
  },
  calculateCredits(payload) {
    const url = `/planner/students-idp/calculate-credits/`
    return axios.post(url, payload)
  }
}

export const MissionControl = {
  ...Service('/mission-control/:id/'),
  fetchLogs: (urlParams, params = {}) => {
    const url = `/mission-control/:id/logs/`
    return axios.get(prepareURL(url, urlParams), { params })
  },
  logChoices: () => {
    return axios.get('/mission-control/log-choices/')
  }
}

export const DegreeAim = Service('/mission-control/degree-aims/:id/')

export const Interests = Service('/mission-control/interests/:id/')

export const Skill = Service('/mission-control/skills/:id/')

export const Competency = Service('/mission-control/competencies/:id/')

export const Achievement = Service('/achievements/:id/')

export const StudentAchievement = Service(
  '/achievements/students/:studentId/:id/'
)

export const StudentTAEProject = Service(
  '/mission-control/tae/projects/students/:studentId/:id/'
)

export const TAEExperience = Service('/mission-control/tae/experiences/:id/')

export const StudentTAEExperience = Service(
  '/mission-control/tae/experiences/students/:studentId/:id/'
)

export const StudentTAECourse = Service(
  '/mission-control/tae/courses/students/:studentId/:id/'
)

export const StudentExam = {
  ...Service('/exams/students/:studentId/:id/'),
  types() {
    return axios.get('/exams/external-types/')
  },
  uploadCertificate(id, payload) {
    const formData = getFormDataFromJSON(payload)
    return axios.post(`/exams/${id}/upload-certificate/`, formData, {
      'content-type': 'multipart/form-data'
    })
  }
}

export const StudentTranscript = {
  ...Service('/exams/transcripts/students/:studentId/:id/'),
  uploadCertificate(id, payload) {
    const formData = getFormDataFromJSON(payload)
    return axios.post(
      `/exams/transcripts/${id}/upload-certificate/`,
      formData,
      { 'content-type': 'multipart/form-data' }
    )
  }
}

export const StudentCreditsHistory = {
  ...Service('/mission-control/requirements/credits/students/:studentId/'),
  import(id, payload) {
    const formData = getFormDataFromJSON(payload)
    return axios.post(
      `/mission-control/requirements/credits/students/${id}/import-history/`,
      formData,
      { 'content-type': 'multipart/form-data' }
    )
  }
}

export const StudentCourseRequirement = Service(
  '/mission-control/requirements/students/:studentId/'
)

export const BoardingAttendance = {
  ...Service('/boarding/attendances/:id/'),
  marks() {
    const url = '/boarding/attendances/marks/'
    return axios.get(url)
  }
}

export const BoardingAttendanceReport = {
  ...Service('/boarding/attendances/reports/:id/'),
  statuses() {
    const url = '/boarding/attendances/reports/statuses/'
    return axios.get(url)
  }
}

export const BillingReports = {
  ...Service('/billings/reports/:id/'),
  authors() {
    const url = '/billings/reports/authors/'
    return axios.get(url)
  },
  options() {
    const url = '/billings/reports/options/'
    return axios.get(url)
  },
  downloadReport(reportId, params) {
    const url = `/billings/reports/${reportId}/download/`
    return axios.get(url, {
      params,
      responseType: 'blob'
    })
  },
  withItems(reportId) {
    const url = `/billings/reports/${reportId}/items/`
    return axios.get(url)
  },
  preview(payload) {
    const url = '/billings/reports/preview/'
    return axios.post(url, payload)
  }
}

export const BoardingComments = {
  ...Service('/boarding/boarding-comments/:id/')
}

export const NightAttendanceReport = {
  ...Service('/boarding/night-attendances/reports/:id/'),
  statuses() {
    const url = '/boarding/night-attendances/reports/statuses/'
    return axios.get(url)
  },
  creators() {
    const url = '/boarding/night-attendances/reports/creators/'
    return axios.get(url)
  }
}

export const ProgrammeAttendanceReport = {
  ...Service('/school-programmes/:programmeId/attendance-reports/:id/')
}

export const ProgrammeStudentAttendance = {
  ...Service(
    '/school-programmes/:programmeId/attendance-reports/:reportId/students/:id/'
  ),
  enrolmentHouseName: urlParams => {
    const url =
      '/school-programmes/:programmeId/attendance-reports/:reportId/students/:id/enrolment-house-name'
    return axios.get(prepareURL(url, urlParams))
  }
}

export const NightAttendance = {
  ...Service('/boarding/night-attendances/:id/'),
  marks() {
    const url = '/boarding/night-attendances/marks/'
    return axios.get(url)
  },
  downloadReport(week) {
    const url = '/boarding/night-attendances/report/'
    const params = { week }
    return axios.get(url, { params })
  }
}

export const NightAttendanceComment = {
  ...Service('/boarding/night-attendances/:attendanceId/comments/:id/'),
  fetch(attendanceId, params = {}) {
    const url = `/boarding/night-attendances/${attendanceId}/comments/`
    return axios.get(url, { params })
  },
  save(attendanceId, payload) {
    const url = `/boarding/night-attendances/${attendanceId}/comments/`
    return axios.post(url, payload)
  }
}

export const House = Service('/boarding/houses/:id/')

export const HouseRoom = {
  ...Service('/boarding/houses/rooms/:id/'),
  codes() {
    return axios.get('/boarding/houses/rooms/codes/')
  }
}

export const Tutor = {
  fetch(params) {
    const url = '/boarding/houses/tutors/'
    return axios.get(url, { params })
  }
}
export const LeaveRequest = Service('/leaves/requests/:id/')

export const LeaveCheck = Service('/leaves/checks/:id/')

export const Doctor = Service('/doctors/:id/')

export const Hospital = Service('/health/hospitals/:id/')

export const DoctorAppointment = {
  ...Service('/sick-reports/appointments/:id/'),
  getDiagnosis(appointmentId) {
    const url = `/sick-reports/appointments/${appointmentId}/get-diagnosis/`
    return axios.get(url)
  },
  setDiagnosis(appointmentId, payload) {
    const url = `/sick-reports/appointments/${appointmentId}/set-diagnosis/`
    return axios.patch(url, payload)
  }
}

export const SickReport = {
  ...Service('/sick-reports/:id/'),
  close(reportId, close_reason) {
    const url = `/sick-reports/${reportId}/close/`
    return axios.post(url, { close_reason })
  },
  open(reportId) {
    const url = `/sick-reports/${reportId}/open/`
    return axios.post(url)
  },
  closeReasons() {
    const url = `/sick-reports/close-reasons/`
    return axios.get(url)
  },
  symptoms() {
    const url = `/sick-reports/symptoms/`
    return axios.get(url)
  },
  hasActiveMedicineInPlan(reportId) {
    const url = `/sick-reports/${reportId}/has-active-medicine-in-plan/`
    return axios.get(url)
  },
  openedCovidReports(params) {
    const url = `/sick-reports/opened-covid-reports/`
    return axios.get(url, { params })
  },
  requestClosure(reportId, close_reason) {
    const url = `/sick-reports/${reportId}/request-closure/`
    return axios.post(url, { close_reason })
  },
  approveClosure(reportId) {
    const url = `/sick-reports/${reportId}/approve-closure/`
    return axios.post(url)
  },
  rejectClosure(reportId) {
    const url = `/sick-reports/${reportId}/reject-closure/`
    return axios.post(url)
  }
}

export const SickReportAbsenceDates = Service(
  '/sick-reports/sick-report-absence-dates/:id/'
)

export const HealthCheck = {
  ...Service('/health/checks/:id/'),
  slots() {
    const url = `/health/checks/slots/`
    return axios.get(url)
  },
  medicines(healthCheckId) {
    const url = `/health/checks/${healthCheckId}/medicines/`
    return axios.get(url)
  },
  optionalMedicines(healthCheckId) {
    const url = `/health/checks/${healthCheckId}/optional-medicines/`
    return axios.get(url)
  },
  minAndMaxDate: (urlParams, payload) => {
    const url = `/health/checks/min-and-max-date/`
    return axios.get(prepareURL(url, urlParams), payload)
  },
  availableFilters: (urlParams, payload) => {
    const url = '/health/checks/available-filters/'
    return axios.get(prepareURL(url, urlParams), payload)
  }
}

export const SickReportComment = {
  ...Service('/sick-reports/:reportId/comments/:id/'),
  fetch(reportId, params = {}) {
    const url = `/sick-reports/${reportId}/comments/`
    return axios.get(url, { params })
  },
  save(reportId, payload) {
    const url = `/sick-reports/${reportId}/comments/`
    return axios.post(url, payload)
  }
}

export const StudentAllergy = {
  ...Service('/allergies/student-allergies/:id/'),
  fetchChoices() {
    return axios.get(`/allergies/student-allergies/choices/`)
  }
}

export const StudentChronicCondition = {
  ...Service('/chronic-conditions/student-chronic-conditions/:id/'),
  fetchChoices() {
    return axios.get(`/chronic-conditions/student-chronic-conditions/choices/`)
  }
}

export const FavouriteStudentsViewSetting = Service(
  '/user-preferences/students-view-setting/:id/'
)

export const TreatmentHistory = {
  fetch: (urlParams, payload) => {
    const url = `/sick-reports/treatment-history/:studentId/`
    return axios.get(prepareURL(url, urlParams), payload)
  }
}

export const StudentCategoryReport = Service(
  '/discipline/student-category-reports/'
)

export const MedicinePlan = {
  fetch: (urlParams, payload) => {
    const url = '/health/medicine-plan/medicine-plan/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  save: (payload, headers = {}) => {
    const url = '/health/medicine-plan/medicine-plan/'
    return axios.post(prepareURL(url), payload, headers)
  }
}

export const MedicineInPlan = {
  fetch: (urlParams, payload) => {
    const url = '/health/medicine-plan/medicine-in-plan/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  save: (payload, headers = {}) => {
    const url = '/health/medicine-plan/medicine-in-plan/'
    return axios.post(prepareURL(url), payload, headers)
  },
  changeEndDate: (urlParams, payload, headers = {}) => {
    const url = '/health/medicine-plan/medicine-in-plan/:id/change-end-date/'
    return axios.put(prepareURL(url, urlParams), payload, { headers })
  },
  getWarningsChangeEndDate: (urlParams, payload, headers = {}) => {
    const url =
      '/health/medicine-plan/medicine-in-plan/:id/get-warnings-change-end-date/'
    return axios.post(prepareURL(url, urlParams), payload, { headers })
  }
}

export const ScheduledMedicine = Service('/health/scheduled-medicine/:id/')

export const CampusAbsenceComment = {
  ...Service('/campus-absences/:campusAbsenceId/comments/:id/'),
  fetch(campusAbsenceId, params = {}) {
    const url = `/campus-absences/${campusAbsenceId}/comments/`
    return axios.get(url, { params })
  },
  save(campusAbsenceId, payload) {
    const url = `/campus-absences/${campusAbsenceId}/comments/`
    return axios.post(url, payload)
  }
}

export const FoodGive = Service('/health/checks/food-give/:id/')

export const DrinkGive = Service('/health/checks/drink-give/:id/')

export const TemperatureCheck = Service('/health/checks/temperature/:id/')

export const ConditionCheck = Service('/health/checks/conditions/:id/')

export const HealthCheckComment = Service('/health/checks/comments/:id/')

export const HealthCheckLog = Service('/health/checks/logs/:id/')

export const MedicineGiven = Service('/health/checks/medicine-given/:id/')

export const Accommodation = Service('/boarding/accommodations/:id/')

export const CalendarEvent = {
  ...Service('/admin/core/calendar/:id/'),
  types() {
    const url = `/admin/core/calendar/types/`
    return axios.get(url)
  }
}

export const Comments = {
  ...Service('/students/comments/'),
  addedByChoices: params => {
    return axios.get('/students/comments/added-by-choices/', params)
  }
}

export const ProgressComment = {
  ...Service('/progress-comments/:id/'),
  availableFilters: (urlParams, payload) => {
    const url = '/progress-comments/available-filters/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  choices: params => {
    return axios.get('/progress-comments/choices/', params)
  },
  changeCommentDeadline: (urlParams, payload, headers = {}) => {
    const url = '/progress-comments/:id/change-comment-deadline/'
    return axios.patch(prepareURL(url, urlParams), payload, { headers })
  },
  evaluate: (urlParams, payload, headers = {}) => {
    const url = '/progress-comments/:id/evaluate/'
    return axios.patch(prepareURL(url, urlParams), payload, { headers })
  },
  saveDraft: (urlParams, payload, headers = {}) => {
    const url = '/progress-comments/:id/save-draft/'
    return axios.patch(prepareURL(url, urlParams), payload, { headers })
  },
  datesForNewProgressComment: params => {
    return axios.get(
      '/progress-comments/dates-for-new-progress-comment/',
      params
    )
  },
  hasInstanceForCurrentWeek: (urlParams, payload) => {
    const url = '/progress-comments/has-instance-for-current-week/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  generalStatistics: (urlParams, payload) => {
    const url = '/progress-comments/general-statistics/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  csv(params) {
    return axios.get('/progress-comments/csv/', {
      params,
      responseType: 'blob'
    })
  }
}

export const Team = {
  ...Service('/teams/:id/'),
  fetchStaffList() {
    return axios.get(`/teams/staff-list/`)
  }
}

export const Task = {
  ...Service('/tasks-management/tasks/:id/'),
  deleteTask: (urlParams, payload) => {
    const url = '/tasks-management/tasks/:id/'
    return axios.delete(prepareURL(url, urlParams), { data: payload })
  },
  availableFilters: (urlParams, payload) => {
    const url = '/tasks-management/tasks/available-filters/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  toggleIsDone: (urlParams, payload, headers = {}) => {
    // refactor to use toggleIsDone backend endpoint
    const url = '/tasks-management/tasks/:id/toggle-is-done/'
    return axios.post(prepareURL(url, urlParams), payload, { headers })
  },
  toggleSubtaskIsDone: (urlParams, payload, headers = {}) => {
    // refactor to use toggleIsDone backend endpoint
    const url = '/tasks-management/tasks/:id//toggle-subtask-is-done/'
    return axios.post(prepareURL(url, urlParams), payload, { headers })
  }
}

export const UserTaskTitle = Service('/tasks-management/user-task-titles/:id/')

export const ExamSession = {
  ...Service('/exams/exam-planner/exam-session/:id/'),
  simpleList(urlParams, payload) {
    const url = `/exams/exam-planner/exam-session/simple-list/`
    return axios.get(prepareURL(url, urlParams), payload)
  },
  xlsx(examSessionId) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/xlsx/`
    return axios.get(url, {
      responseType: 'blob'
    })
  },
  changesPreview(urlParams, payload, headers = {}) {
    const url = `/exams/exam-planner/exam-session/:id/changes-preview/`
    return axios.post(prepareURL(url, urlParams), payload, { headers })
  },
  availableExamCourses: (examSessionId, params = {}) => {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/available-exam-courses/`
    return axios.get(url, { params })
  },
  availableUsers(params = {}) {
    const url = `/exams/exam-planner/exam-session/available-users/`
    return axios.get(url, { params })
  },
  suggestedRooms(params = {}) {
    const url = `/exams/exam-planner/exam-session/suggested-rooms/`
    return axios.get(url, { params })
  },
  students(params = {}) {
    const url = `/exams/exam-planner/exam-session/students/`
    return axios.get(url, { params })
  },
  studentsFromCourses(examSessionId, params = {}) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/students-from-courses/`
    return axios.get(url, { params })
  },
  teachersFromCourses(examSessionId, params = {}) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/teachers-from-courses/`
    return axios.get(url, { params })
  },
  fetchStudentsForExam(examSessionId, params = {}) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/students-for-exam/`
    return axios.get(url, { params })
  },
  fetchExamsReadyToPublish(examSessionId, params = {}) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/exams-ready-to-publish/`
    return axios.get(url, { params })
  },
  publishReadyExams(examSessionId, params = {}) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/publish-ready-exams/`
    return axios.post(url, {}, { params })
  },
  publishExams(examSessionId, examIds, params = {}) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/publish-exams/`
    return axios.post(url, { exams: examIds }, { params })
  },
  withExamsInDateRange(payload = {}) {
    // list of exam session ids which have exams in date range
    const url = `/exams/exam-planner/exam-session/has-exams-in-date-range/`
    return axios.get(url, payload)
  },
  coursesInExamSession(examSessionId) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/courses-in-exam-session/`
    return axios.get(url)
  }
}

export const Exam = {
  ...Service('/exams/exam-planner/exam-session/:examSessionId/exams/:id/'),
  fetch(examSessionId, payload = {}) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/exams/`
    return axios.get(url, payload)
  },
  save(examSessionId, payload) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/exams/`
    return axios.post(url, payload)
  },
  deleteMultiple(urlParams, payload, headers = {}) {
    const url = `/exams/exam-planner/exam-session/:examSessionId/exams/remove-multiple/`
    return axios.post(prepareURL(url, urlParams), payload, { headers })
  },
  dates(examSessionId) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/exams/dates/`
    return axios.get(url)
  },
  allIds(examSessionId, params = {}) {
    const url = `/exams/exam-planner/exam-session/${examSessionId}/exams/all-ids/`
    return axios.get(url, { params })
  },
  availableFilters: (urlParams, payload) => {
    const url =
      '/exams/exam-planner/exam-session/:examSessionId/exams/available-filters/'
    return axios.get(prepareURL(url, urlParams), payload)
  }
}

export const LogEntry = {
  forObject({ model, objectId, params = {} }) {
    const url = '/logs/'
    params.object = `${model},${objectId}`
    return axios.get(url, { params })
  }
}

export const ExamEnrolledStudent = Service(
  '/exams/exam-planner/exam-enrolled-student/:id/'
)

export const ExamClash = {
  ...Service('/exams/exam-planner/exam-clashes/'),
  preview(data, config) {
    const url = `/exams/exam-planner/exam-clashes/exam-clash-preview/`
    return axios.post(url, data, config)
  }
}

export const MyTimetable = {
  fetch: (urlParams, payload) => {
    return axios.get(prepareURL('/user-timetable/', urlParams), payload)
  },
  downloadPdf(params) {
    const url = `/user-timetable/pdf/`
    return axios.get(url, {
      params,
      responseType: 'blob'
    })
  }
}

export const SchoolProgrammeAttachment = {
  upload: (file, config = {}) => {
    const url = '/attachments/schoolprogrammeattachment/'

    const formData = new FormData()
    formData.append('file', file)

    return axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...config
    })
  }
}

export const InternshipAttachment = {
  upload: (file, config = {}) => {
    const url = '/attachments/internshipattachment/'

    const formData = new FormData()
    formData.append('file', file)

    return axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...config
    })
  }
}
export const SchoolProgrammeAvailableStudents = {
  ...Service('/school-programmes/available-students/'),
  availableFilters: (urlParams, payload) => {
    const url = '/school-programmes/available-students/available-filters/'
    return axios.get(prepareURL(url, urlParams), payload)
  }
}

export const SchoolProgramme = {
  ...Service('/school-programmes/:id/'),
  availableStaffUsers: (urlParams, payload) => {
    const url = '/school-programmes/available-staff-users/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  availableFilters: (urlParams, payload) => {
    const url = '/school-programmes/available-filters/'
    return axios.get(prepareURL(url, urlParams), payload)
  },
  approveProgramme: programmeId => {
    const url = `/school-programmes/${programmeId}/approve-programme/`
    return axios.post(url)
  },
  rejectProgramme: (programmeId, payload) => {
    const url = `/school-programmes/${programmeId}/reject-programme/`
    return axios.post(url, payload)
  },
  requestProgramme: programmeId => {
    const url = `/school-programmes/${programmeId}/request-programme/`
    return axios.post(url)
  }
}
export const ProgrammeEnrolment = {
  setAsApproved: (enrolmentId, payload) => {
    const url = `/school-programmes/enrolments/${enrolmentId}/set-as-approved/`
    return axios.post(url, payload)
  },
  setAsRejected: (enrolmentId, payload) => {
    const url = `/school-programmes/enrolments/${enrolmentId}/set-as-rejected/`
    return axios.post(url, payload)
  },
  setAsRequested: (enrolmentId, payload) => {
    const url = `/school-programmes/enrolments/${enrolmentId}/set-as-requested/`
    return axios.post(url, payload)
  },
  setManyAsApproved: payload => {
    const url = `/school-programmes/enrolments/set-many-as-approved/`
    return axios.post(url, payload)
  },
  setManyAsRejected: payload => {
    const url = `/school-programmes/enrolments/set-many-as-rejected/`
    return axios.post(url, payload)
  },
  setManyAsRequested: payload => {
    const url = `/school-programmes/enrolments/set-many-as-requested/`
    return axios.post(url, payload)
  }
}

export const StudentsClashes = {
  studentsClashes: (payload, config = {}) => {
    const url = '/admin/students/clashes/'
    return axios.post(url, payload, config)
  }
}

export const StaffUsersClashes = {
  staffUsersClashes: (payload, config = {}) => {
    const url = '/admin/users/clashes/'
    return axios.post(url, payload, config)
  }
}

export const Image = {
  ...Service('/images/:id/'),
  upload: (file, config = {}) => {
    const url = '/images/'

    const formData = new FormData()
    formData.append('file', file)

    return axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...config
    })
  }
}
