import Vue, { h, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'

import type { RotaGenerationTask } from '@/api/__generated-api'
import { httpService } from '@/api/http.service'
import {
  errorNotification,
  infoNotification,
  successNotification
} from '@/utils/notifications'

import { useFetch } from './useFetch'

export type Task = Omit<RotaGenerationTask, 'teams' | 'quarter' | 'created'>

const taskId = ref(0)
const boardingRotaRequestId = ref<string | null>(null)
const timetableId = ref<number>()

export function useBoardingRotaNotification(refetchBoardingRotas?: () => void) {
  const router = useRouter()
  const route = useRoute()

  const { refetch: refetchActiveList } = useFetch(
    ['boardingRotaActiveList', route.params.timetableId],
    async () => {
      const response =
        await httpService.boarding.boardingRotaGenerationActiveList({
          timetable: Number(route.params.timetableId)
        })

      if (response?.length) {
        boardingRotaRequestId.value = response[0].id.toString()
        localStorage.setItem('boardingRotaRequestId', response[0].id.toString())
        taskId.value = response[0].id
        checkStatus()
        createInfoNotification()
      }
      return response
    },
    {
      enabled: false,
      refetchOnWindowFocus: false
    }
  )

  const setTaskId = (id: number) => {
    taskId.value = id
  }

  const { refetch: checkStatus } = useFetch(
    ['boardingRota', taskId],
    async () => {
      if (!localStorage.getItem('boardingRotaRequestId')) return {}

      const response =
        await httpService.boarding.boardingRotaGenerationRetrieve(
          taskId.value,
          {
            fetchKeys: {
              finished_at: true,
              timetable: true,
              rota_timetable_id: true,
              status: true,
              id: true
            }
          }
        )

      if (!response.finished_at) {
        timetableId.value = response.timetable || undefined
        window.setTimeout(() => {
          checkStatus()
        }, 5000)
      } else {
        timetableId.value = undefined
        clearNotification()

        if (boardingRotaRequestId.value) {
          localStorage.removeItem('boardingRotaRequestId')
          boardingRotaRequestId.value = ''
        }
      }

      showNotification(response)

      return response
    },
    {
      enabled: false,
      refetchOnWindowFocus: false
    }
  )

  const clearNotification = () => {
    Vue.prototype.$notification.close('boarding-rota-in-progress-notification')
  }

  const createInfoNotification = () => {
    infoNotification('Generation of Boarding Rota', {
      duration: 0,
      class: 'boarding-rota-notification',
      key: 'boarding-rota-in-progress-notification',
      icon: () => h('a-spin')
    })
  }

  const createSuccessNotification = (value: Task) => {
    successNotification(
      `Generation of Boarding Rota: ${value.rota_timetable_id} finished.`,
      {
        description: () =>
          h(
            'div',
            {
              on: {
                click: () => {
                  router.push({
                    name: 'timetable-boarding-rota',
                    params: {
                      timetableId: value.timetable?.toString() || ''
                    },
                    query: {
                      selected_rota: value.rota_timetable_id?.toString()
                    }
                  })
                  Vue.prototype.$notification.close(
                    'boarding-rota-success-notification'
                  )
                }
              }
            },

            [
              'Go to ',
              h(
                'span',
                { class: 'go-to-span' },
                `Boarding Rota: ${value.rota_timetable_id}`
              )
            ]
          ),
        duration: 0,
        class: 'boarding-rota-notification-success',
        key: 'boarding-rota-success-notification'
      }
    )
  }

  const showNotification = (value: Task) => {
    if (
      (value?.status === 'failed' || value?.status === 'success') &&
      !!route.params.timetableId
    ) {
      clearNotification()
      refetchBoardingRotas && refetchBoardingRotas()
      refetchActiveList()
    }

    if (value?.status === 'success') createSuccessNotification(value)
    if (value?.status === 'failed')
      errorNotification(`Generation of Boarding Rota: ${value.id} failed.`)
  }

  onMounted(() => {
    boardingRotaRequestId.value = localStorage.getItem('boardingRotaRequestId')

    if (!boardingRotaRequestId.value && !!refetchBoardingRotas)
      refetchActiveList()
  })

  return {
    boardingRotaRequestId,
    checkStatus,
    createInfoNotification,
    setTaskId,
    timetableId
  }
}
