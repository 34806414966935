import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

export const cancelledLessonsChildrenRoutes = [
  {
    path: '',
    name: 'cancelled-lessons-list' as const,
    component: () =>
      import('@/views/CancelledLessons/CancelledLessonsView.vue'),
    meta: {
      title: 'Cancelled lessons',
      tab: 'academics'
    }
  }
] satisfies Route[]

export const cancelledLessonsRoutes = [
  {
    path: 'cancelled-lessons',
    name: 'cancelled-lessons' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Cancelled lessons',
      tab: 'academics',
      icon: 'close-circle',
      permissions: ['lesson_cancellation.view_cancellesson']
    },
    redirect: () => getFirstPossibleRoute(cancelledLessonsChildrenRoutes),
    children: cancelledLessonsChildrenRoutes
  }
] satisfies Route[]
