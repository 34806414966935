import { httpService } from '@/api/http.service'

import { useFetch } from '../composables/useFetch'

export const useWsAuthTokenRetrieve = ({
  enabled = false
}: {
  enabled: boolean
}) =>
  useFetch(
    ['wsAuthTokenRetrieve'],
    () => httpService.ws.wsAuthTokenRetrieve(),
    { enabled, refetchOnWindowFocus: false }
  )
