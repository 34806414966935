import type { Route } from '@/types/route'

import { academicsRoutes } from './academics/academics'
import { administrationRoutes } from './administration/administration'
import { boardingRoutes } from './boarding/boarding'
import { dashboardRoutes } from './dashboard'
import { studentsRoutes } from './students/students'
import { teacherRoutes } from './teacher'

const publicRoutes = [
  {
    path: '',
    name: 'public-routes' as const,
    meta: {},
    component: () => import('@/views/Layouts/MiddleBox.vue'),
    children: [
      {
        path: '',
        name: 'Login' as const,
        component: () => import('@/views/Login/LoginView.vue'),
        meta: {
          public: true,
          title: 'Log in'
        }
      },
      {
        path: 'reset-password',
        name: 'reset-password' as const,
        component: () => import('@/views/PasswordReset/Reset/index.vue'),
        meta: {
          public: true,
          title: 'Reset password'
        }
      },
      {
        path: 'set-password/:uid/:token',
        name: 'PasswordResetFinish' as const,
        component: () => import('@/views/PasswordReset/Finish/index.vue'),
        meta: {
          public: true,
          title: 'Set a password'
        }
      }
    ]
  },
  {
    path: '/leave-request/approve',
    name: 'leave-request-approve' as const,
    component: () => import('@/views/Attendance/LeaveRequest/Review/index.vue'),
    meta: {
      public: true,
      title: 'Review leave request'
    }
  }
] satisfies Route[]

const commonRoutes = [
  {
    path: '',
    name: 'common-routes' as const,
    redirect: '/teacher/timetable',
    meta: {},
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        path: 'exam-planner/exam-attendance/:examSession/:exam',
        name: 'exam-attendance' as const,
        component: () => import('@/views/ExamPlanner/ExamAttendance/index.vue'),
        meta: {
          title: 'Exam attendance',
          tab: 'academics'
        }
      },
      {
        path: 'school-programmes/:programmeId/attendance-reports',
        name: 'ProgrammeAttendance' as const,
        component: () =>
          import(
            '@/views/Boarding/SchoolProgrammes/ProgrammeAttendance/ProgrammeAttendanceCollapse.vue'
          ),
        meta: {
          title: 'Programme attendance',
          tab: 'academics'
        }
      },
      ...dashboardRoutes,
      ...academicsRoutes,
      ...boardingRoutes,
      ...administrationRoutes,
      ...studentsRoutes
    ]
  }
] satisfies Route[]

const wildcardRoutes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found' as const,
    meta: {},
    component: () => import('@/views/PageNotFound/PageNotFound.vue')
  }
] satisfies Route[]

const privateRoutes = [
  {
    path: '',
    name: 'private-routes' as const,
    redirect: '/my-timetable',
    meta: {},
    component: () => import('@/views/Layouts/SidebarContent.vue'),
    children: [...commonRoutes, ...teacherRoutes, ...wildcardRoutes]
  }
] satisfies Route[]

export const routes = [...publicRoutes, ...privateRoutes] satisfies Route[]
