import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

import { absenceRoutes } from './absence'
import { alertsRoutes } from './alerts'
import { artisanTimetableRoutes } from './artisan-timetable'
import { artisansCoversRoutes } from './artisans-covers'
import { cancelledLessonsRoutes } from './cancelled-lessons'
import { coursesOverviewRoutes } from './courses-overview'
import { idpPlansRoutes } from './idp-plans'
import { myTimetableRoutes } from './my-timetable'
import { progressAlertsRoutes } from './progress-alerts'
import { studentTimetableRoutes } from './student-timetable'
import { timetablePreviewRoutes } from './timetable-preview'

export const academicsChildrenRoutes = [
  ...myTimetableRoutes,
  ...artisanTimetableRoutes,
  ...studentTimetableRoutes,
  ...timetablePreviewRoutes,
  ...artisansCoversRoutes,
  ...coursesOverviewRoutes,
  ...absenceRoutes,
  ...cancelledLessonsRoutes,
  ...alertsRoutes,
  ...progressAlertsRoutes,
  ...idpPlansRoutes
] satisfies Route[]

export const academicsRoutes = [
  {
    path: '',
    name: 'academics' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      tab: 'academics',
      title: 'Academics'
    },
    redirect: () => getFirstPossibleRoute(academicsChildrenRoutes),
    children: academicsChildrenRoutes
  }
] satisfies Route[]
