import type { Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/getFirstPossibleRoute'

const schoolYearsChildrenRoutes = [
  {
    path: '',
    name: 'settings-school-years' as const,
    component: () =>
      import('@/views/AdminPanel/Settings/SchoolYears/SchoolYearsView.vue'),
    meta: {
      title: 'School Years',
      icon: 'calendar',
      tab: 'administration',
      permissions: ['periods.change_schoolyear']
    }
  },
  {
    path: 'add',
    name: 'settings-school-years-add' as const,
    component: () =>
      import('@/views/AdminPanel/Settings/SchoolYearForm/index.vue'),
    meta: {
      title: 'Add School Year',
      tab: 'administration',
      permissions: ['periods.add_schoolyear']
    }
  },
  {
    path: ':yearId',
    name: 'settings-school-years-edit' as const,
    component: () =>
      import('@/views/AdminPanel/Settings/SchoolYearForm/index.vue'),
    meta: {
      title: 'Edit School Year',
      tab: 'administration',
      permissions: ['periods.change_schoolyear']
    }
  }
] satisfies Route[]

export const settingsTabRoutes = [
  {
    path: 'settings',
    name: 'change-settings' as const,
    component: () =>
      import('@/views/AdminPanel/Settings/Settings/SettingsView.vue'),

    meta: {
      icon: 'setting',
      title: 'Settings',
      tab: 'administration',
      permissions: [
        'core.change_sitesettings',
        'permissions.allow_set_potential_grades'
      ]
    }
  },
  {
    path: 'school-years',
    name: 'settings-school-years-tab' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'School Years',
      icon: 'calendar',
      tab: 'administration'
    },
    redirect: () => getFirstPossibleRoute(schoolYearsChildrenRoutes),
    children: schoolYearsChildrenRoutes
  },
  {
    path: 'weekend-planner',
    name: 'settings-weekend-planner' as const,
    meta: {
      icon: 'schedule',
      title: 'Weekend Planner',
      tab: 'administration',
      permissions: ['core.change_calendarevent']
    },
    component: () =>
      import(
        '@/views/AdminPanel/Settings/WeekendPlanner/WeekendPlannerView.vue'
      )
  },
  {
    path: 'school-break',
    name: 'settings-school-break' as const,
    meta: {
      icon: 'carry-out',
      title: 'School Breaks',
      tab: 'administration',
      permissions: ['school_breaks.view_schoolbreak']
    },
    component: () =>
      import('@/views/AdminPanel/Settings/SchoolBreak/SchoolBreakView.vue')
  },
  {
    path: 'health-card',
    name: 'settings-health-card' as const,
    meta: {
      icon: 'calendar',
      title: 'Health Card',
      tab: 'administration',
      permissions: ['allergies.view_allergy']
    },
    component: () =>
      import('@/views/AdminPanel/Settings/HealhCard/HealthCardView.vue')
  },
  {
    path: 'merits-demerits-management',
    name: 'settings-merits-demerits-management' as const,
    meta: {
      icon: 'rise',
      title: 'Merits & Demerits Management',
      tab: 'administration',
      permissions: ['merits.change_merititem']
    },
    component: () =>
      import(
        '@/views/AdminPanel/Settings/MeritsDemeritsManagement/MeritsDemeritsManagementView.vue'
      )
  }
] satisfies Route[]

const settingsNotTabRoutes = [
  {
    path: '',
    name: 'settings-tab' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: settingsTabRoutes
    },
    meta: {
      title: 'Settings',
      tab: 'administration'
    },
    redirect: () => getFirstPossibleRoute(settingsTabRoutes),
    children: settingsTabRoutes
  },
  {
    path: 'merits-demerits-management/:id',
    name: 'settings-merits-demerits-management-edit-item' as const,
    component: () =>
      import(
        '@/views/AdminPanel/Settings/MeritsDemeritsManagement/MeritsDemeritsManagementEditView.vue'
      ),
    meta: {
      title: 'Edit Merit & Demerit',
      tab: 'administration',
      permissions: ['merits.change_merititem']
    }
  }
] satisfies Route[]

export const settingsRoutes = [
  {
    path: 'settings',
    name: 'settings' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Settings',
      tab: 'administration',
      icon: 'setting',
      permissions: ['core.change_sitesettings']
    },
    redirect: () => getFirstPossibleRoute(settingsNotTabRoutes),
    children: settingsNotTabRoutes
  }
] satisfies Route[]
