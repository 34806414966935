import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/getFirstPossibleRoute'

const artisanTimetableChildRoutes = [
  {
    path: '',
    name: 'artisan-timetable-view' as const,
    component: () =>
      import('@/views/AdminPanel/Timetable/Teacher/TeacherTimetableView.vue'),
    meta: {
      title: 'Artisan Timetable',
      tab: 'academics',
      permissions: ['permissions.view_teachers_timetable']
    }
  },
  {
    path: ':lessonId',
    name: 'artisan-timetable-activity' as const,
    component: () => import('@/views/TeacherPanel/TimetableDetails.vue'),
    meta: {
      title: 'Artisan Timetable',
      tab: 'academics',
      permissions: ['activities.view_lesson']
    }
  }
] satisfies Route[]

export const artisanTimetableRoutes = [
  {
    path: 'timetable/teacher',
    name: 'artisan-timetable' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Artisan timetable',
      tab: 'academics',
      icon: 'calendar',
      permissions: ['permissions.view_teachers_timetable']
    },
    redirect: () => getFirstPossibleRoute(artisanTimetableChildRoutes),
    children: artisanTimetableChildRoutes
  }
] satisfies Route[]
