import { Modal } from 'ant-design-vue'
import Vue from 'vue'
import VueRouter, {
  NavigationFailureType,
  isNavigationFailure
} from 'vue-router'

import store from '@/store/'
import { checkIsRouteWithFeatureEnabled } from '@/utils/feature-flags'
import { routes } from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes
})

const delphiSuggestionPreviewRouteName = 'timetable-suggestions-preview'
const timetableDetailsRouteName = 'timetable-details'

const routeGuard = async (to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} — Rosenberg` : 'Rosenberg'

  const publicRoute = to.meta.public

  if (publicRoute) {
    next()
    return
  }

  if (
    from.name === delphiSuggestionPreviewRouteName &&
    ![timetableDetailsRouteName, delphiSuggestionPreviewRouteName].includes(
      to.name
    )
  ) {
    showDelphiSuggestionLeaveModal(next)
    return
  }

  const { profiles } = store.state.userInfo
  const { permissions } = store.state.userInfo

  if (!publicRoute && !profiles) {
    Vue.prototype.$notification.error({
      message: 'This page is only for logged in users. Log in!'
    })
    next({ name: 'Login', query: { next: to.path } })
    return
  }

  const routePermissions = to.meta?.permissions
  if (routePermissions) {
    const hasPermission = routePermissions.some(routePermission =>
      permissions.includes(routePermission)
    )

    if (!hasPermission || !checkIsRouteWithFeatureEnabled(to)) {
      Vue.prototype.$notification.error({
        message: "You don't have permission to view this page."
      })
      next({ name: 'Login' })
      return
    }
  }

  next()
}

router.beforeEach(routeGuard)

if (import.meta.env.PROD) {
  const handleDuplicatedNavigation = error => {
    if (!isNavigationFailure(error, NavigationFailureType.duplicated))
      throw error
  }

  const originalPush = router.push
  router.push = function push(to) {
    return originalPush.call(this, to).catch(handleDuplicatedNavigation)
  }

  const originalReplace = router.replace
  router.replace = function replace(to) {
    return originalReplace.call(this, to).catch(handleDuplicatedNavigation)
  }
}

const showDelphiSuggestionLeaveModal = next => {
  Modal.confirm({
    title: 'Leave changes preview',
    content:
      'Do you want to leave timetable changes preview? You can apply the move here or in the original tab.',
    okText: 'Leave',
    centered: true,
    onOk: () => {
      next()
      return
    },
    onCancel: () => {
      return
    }
  })
}

export default router
