import { Modal, notification } from 'ant-design-vue'
import { notification as antNotification } from 'ant-design-vue'
import type { ModalConfirm } from 'ant-design-vue/types/modal'
import { h, onUnmounted, provide, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

import { websocketService } from '@/api/websocket.service'
import BaseNotificationWithButton from '@/components/base/BaseNotificationWithButton.vue'
import { useNewStudentsCounter } from '@/composables/useNewStudentsCounter'
import type { RosenLocation } from '@/types/route'
import {
  errorNotification,
  infoNotification,
  successNotification
} from '@/utils/notifications'
import type { DelphiProcessStatus } from '@/views/Planner/TimetableDetail/Delphi/types'

import {
  DraftActivitySuggesterKey,
  ImportConflictKey,
  MarkAsDeliveredKey,
  TimetableImportNotificationKey,
  taeGenerationNotification
} from './keys'
import type {
  TAEGenerationNotificationData,
  TAEUserSuccessGenerationNotification,
  WebsocketEvent
} from './types'
import type {
  AdditionalQueryParams,
  DraftActivitySuggesterTask,
  DraftActivitySuggesterTaskEvent,
  ImportConflict,
  SuggesterUserSession,
  TimetableImportFinishedEvent,
  TimetableImportNotification
} from './types'
import type { DelphiChannelEventData } from '../useDelphi/types'
import { useEvent } from '../useEvent'
import { useModal } from '../useModal'
import { useMultiTabBroadcast } from '../useMultiTabBroadcast'
import { useNewStudentsBanner } from '../useNewStudentsBanner'

export function useNotificationsWebsocket({
  goToTimetableDetails,
  setDelphiSession,
  regenerateTaeGenerationAfterFail
}: {
  goToTimetableDetails: (
    eventData: DraftActivitySuggesterTaskEvent,
    additionalQueryParams: AdditionalQueryParams
  ) => void
  onDelphiTryAgain: (eventData: DraftActivitySuggesterTaskEvent) => void
  setDelphiSession: (value?: SuggesterUserSession) => void
  regenerateTaeGenerationAfterFail: (payload: {
    taskId: number
    timetableId: number
  }) => void
}) {
  const draftActivitySuggesterNotification = ref<DraftActivitySuggesterTask>()
  const importConflict = ref<ImportConflict>({ inProgress: false })
  const timetableImportNotification = ref<TimetableImportNotification>()
  const restartDelphiModal = ref<ModalConfirm>()

  const { showInformationModal } = useModal()
  const { setNewStudentEntries } = useNewStudentsBanner()
  const { setNewStudentsCounter } = useNewStudentsCounter()
  const route = useRoute()
  const router = useRouter()

  const { dispatchEvent: dispatchDelphiResetEvent } = useEvent({
    name: 'delphi-reset'
  })
  const { dispatchEvent: dispatchDelphiTimeoutEvent } = useEvent({
    name: 'delphi-timeout'
  })
  const { dispatchEvent: dispatchTimetablePreviewResetEvent } = useEvent({
    name: 'timetable-preview-reset'
  })
  const { data: delphiChannelData } = useMultiTabBroadcast<
    DelphiChannelEventData,
    DelphiChannelEventData
  >({
    channelName: 'delphi-channel',
    onEvent: event => {
      if (event.data.type === 'overtake-delphi') {
        restartDelphiModal.value?.destroy()
      }
    }
  })

  const goToTaeResults = (eventData: TAEUserSuccessGenerationNotification) => {
    const timetableDetails: RosenLocation = {
      name: 'timetable-details',
      params: {
        timetableId: eventData.timetable_id.toString()
      },
      query: {
        backPath: '/planner/timetables',
        backName: 'Timetables & Boarding Rotas',
        openTaeModal: 'true'
      }
    }

    router.push(timetableDetails)
  }

  const markAsDelivered = (id: number) => {
    send(
      JSON.stringify({
        event_type: 'ws_notification_delivered',
        notification_id: id
      })
    )
  }

  const setDraftActivitySuggesterNotification = (
    value?: DraftActivitySuggesterTask
  ) => (draftActivitySuggesterNotification.value = value)

  const useNotificationType = {
    info: infoNotification,
    success: successNotification
  }

  const displayTaeGenerationNotification = (
    { message, context: { notification_id } }: TAEGenerationNotificationData,
    notificationConfig: {
      key: keyof typeof taeGenerationNotification
      type: keyof typeof useNotificationType
    }
  ) => {
    useNotificationType[notificationConfig.type](message, {
      key: taeGenerationNotification[notificationConfig.key]
    })
    markAsDelivered(notification_id)
  }

  provide(DraftActivitySuggesterKey, {
    draftActivitySuggesterNotification,
    setDraftActivitySuggesterNotification
  })
  provide(ImportConflictKey, importConflict)
  provide(MarkAsDeliveredKey, markAsDelivered)
  provide(TimetableImportNotificationKey, {
    timetableImportNotification,
    setTimetableImportNotification: () => setTimetableImportNotification()
  })

  const { open, close, send } = websocketService.notifications({
    immediate: false,
    onMessage: (_, event: MessageEvent) => {
      const eventData = (JSON.parse(event.data) as WebsocketEvent).data
      setTimetableImportNotification()

      if (eventData.event_type === 'timetable_import_started') {
        setTimetableImportNotification({
          rotaTimetableId: eventData.rota_timetable_id,
          status: eventData.event_type,
          timetableId: eventData.timetable_id
        })
      }
      if (eventData.event_type === 'timetable_import_failed') {
        setTimetableImportNotification({
          numberOfChanges: eventData.number_of_changes,
          rotaTimetableId: eventData.rota_timetable_id,
          status: eventData.event_type,
          taskId: eventData.task_id,
          timetableId: eventData.timetable_id
        })
      }
      if (eventData.event_type === 'timetable_import_succeeded') {
        setTimetableImportNotification({
          numberOfChanges: eventData.number_of_changes,
          rotaTimetableId: eventData.rota_timetable_id,
          status: eventData.event_type,
          timetableId: eventData.timetable_id
        })
      }

      if (eventData.event_type === 'room_assignment_completed') {
        successNotification(eventData.message, { duration: 0 })
      }

      if (eventData.event_type === 'room_assignment_failed') {
        errorNotification(eventData.message, { duration: 0 })
      }

      if (
        eventData.event_type === 'timetable_autoimport_failed' ||
        eventData.event_type === 'timetable_import_failed'
      ) {
        const notificationId = eventData.context.notification_id
        const taskId = eventData.task_id

        errorNotification(
          () =>
            h(BaseNotificationWithButton, {
              props: {
                buttonText: 'Details',
                buttonVisible: Boolean(taskId),
                message: eventData.message,
                onClick: () => goToTimetableTaskDetails(eventData)
              }
            }),
          { duration: 0, key: 'import-failed-notification' }
        )
        markAsDelivered(notificationId)
        setImportConflict({ inProgress: false })
      }

      if (eventData.event_type === 'user_new_students_cache_banner_changed') {
        return setNewStudentEntries(eventData.banner_data)
      }

      if (eventData.event_type === 'user_new_students_cache_counter_changed') {
        return setNewStudentsCounter(eventData.new_students_count)
      }

      if (eventData.event_type === 'suggester_user_session_started') {
        return setDelphiSession({
          activityName: eventData.activity_name,
          timetableId: eventData.timetable_id,
          taskAuthorId: eventData.task_author_id,
          taskAuthorName: eventData.task_author_name
        })
      }

      if (eventData.event_type === 'suggester_user_session_finished') {
        resetDelphi()
        setDelphiSession()
        return
      }

      if (eventData.event_type === 'suggester_user_session_timeout') {
        resetDelphi()
        dispatchDelphiTimeoutEvent()
        markAsDelivered(eventData.context.notification_id)

        restartDelphiModal.value = showInformationModal('confirm', {
          title: 'Restart Delphi',
          content: 'Due to your inactivity, Delphi was turned off.',
          icon: 'info-circle',
          okText: 'Restart',
          cancelText: 'Close',
          onOk: () => {
            setDraftActivitySuggesterValue(eventData, 'not_started')
            goToTimetableDetails(eventData, { restart: 'true' })
          }
        })
        return
      }

      if (
        [
          'timetable_autoimport_started',
          'timetable_import_in_progress',
          'timetable_import_started'
        ].includes(eventData.event_type)
      ) {
        infoNotification(eventData.message)
        setImportConflict({
          inProgress: true,
          processName: 'Timetable & Boarding Rota'
        })
      }

      if (
        eventData.event_type === 'timetable_autoimport_succeeded' ||
        eventData.event_type === 'timetable_import_succeeded'
      ) {
        const notificationId = eventData.context.notification_id
        successNotification(eventData.message, { duration: 0 })
        markAsDelivered(notificationId)
        setImportConflict({ inProgress: false })
      }

      if (
        eventData.event_type === 'exam_publishing_in_progress' ||
        eventData.event_type === 'exam_publishing_started'
      ) {
        setImportConflict({ inProgress: true, processName: 'Exam(s)' })
      }

      if (
        ['exam_publishing_completed', 'exam_publishing_failed'].includes(
          eventData.event_type
        )
      ) {
        setImportConflict({ inProgress: false })
      }

      if (eventData.event_type === 'draft_activity_suggester_task_failed') {
        setDraftActivitySuggesterValue(eventData, 'failed')

        errorNotification(
          () =>
            h(BaseNotificationWithButton, {
              props: {
                buttonText: 'Try again',
                message: eventData.message,
                onClick: () => {
                  goToTimetableDetails(eventData, { tryAgain: 'true' })
                  markAsDelivered(eventData.context.notification_id)
                  notification.close('suggester-task-failed-notification')
                }
              }
            }),
          { duration: 0, key: 'suggester-task-failed-notification' }
        )
      }

      if (eventData.event_type === 'draft_activity_suggester_task_running') {
        setDraftActivitySuggesterValue(eventData, 'running')
        infoNotification(eventData.message)
      }
      if (eventData.event_type === 'draft_activity_suggestion_applied') {
        if (delphiChannelData.value?.type !== 'apply-delphi-from-preview')
          return

        // Fields notification_id and years_to_rearrange are not used, provided to make TS happy
        const event = {
          ...eventData,
          context: {
            notification_id:
              draftActivitySuggesterNotification.value?.notificationId || 0
          },
          years_to_rearrange:
            draftActivitySuggesterNotification.value?.yearsToRearrange || []
        }
        setDraftActivitySuggesterValue(event, 'applied')
      }

      if (eventData.event_type === 'draft_activity_suggester_task_succeeded') {
        setDraftActivitySuggesterValue(eventData, 'succeeded')
        if (route.name === 'timetable-suggestions-preview') return
        successNotification(
          () =>
            h(BaseNotificationWithButton, {
              props: {
                buttonText: 'Results',
                message: eventData.message,
                onClick: () => {
                  goToTimetableDetails(eventData, { showResults: 'true' })
                  notification.close('suggester-task-succeeded-notification')
                }
              }
            }),
          { duration: 0, key: 'suggester-task-succeeded-notification' }
        )
      }

      // TAE GENERATION NOTIFICATIONS
      if (eventData.event_type === 'tae_generator_task_running_user')
        displayTaeGenerationNotification(eventData, {
          key: 'start',
          type: 'info'
        })

      if (eventData.event_type === 'tae_generator_task_cancelled_user')
        displayTaeGenerationNotification(eventData, {
          key: 'cancel',
          type: 'info'
        })

      if (eventData.event_type === 'tae_generator_task_succeeded_user') {
        if (route.name !== 'timetable-details') {
          markAsDelivered(eventData.context.notification_id)
          successNotification(
            () =>
              h(BaseNotificationWithButton, {
                props: {
                  buttonText: 'Results',
                  message: eventData.message,
                  onClick: () => goToTaeResults(eventData)
                }
              }),
            { duration: 0, key: taeGenerationNotification.success }
          )
        } else {
          displayTaeGenerationNotification(eventData, {
            key: 'success',
            type: 'success'
          })
        }
      }

      if (
        eventData.event_type === 'tae_generator_task_applying_in_progress_user'
      ) {
        antNotification.close(taeGenerationNotification.success)
        displayTaeGenerationNotification(eventData, {
          key: 'applyingInProgress',
          type: 'info'
        })
      }

      if (eventData.event_type === 'tae_generator_task_applied_user') {
        antNotification.close(taeGenerationNotification.applyingInProgress)
        displayTaeGenerationNotification(eventData, {
          key: 'applied',
          type: 'success'
        })
      }

      if (
        eventData.event_type === 'tae_generator_task_discarded_user' ||
        eventData.event_type === 'tae_generator_task_discarded_after_fail_user'
      ) {
        antNotification.close(taeGenerationNotification.success)
        displayTaeGenerationNotification(eventData, {
          key: 'discarded',
          type: 'info'
        })
      }

      if (eventData.event_type === 'tae_generator_task_failed_user') {
        markAsDelivered(eventData.context.notification_id)

        const hasValidSections = !eventData.infeasible_section_ids?.length

        if (hasValidSections) {
          errorNotification(
            () =>
              h(BaseNotificationWithButton, {
                props: {
                  buttonText: 'Try again',
                  message: 'Generate suggestions process failed',
                  onClick: () => {
                    regenerateTaeGenerationAfterFail({
                      taskId: eventData.task_id,
                      timetableId: eventData.timetable_id
                    })
                    antNotification.close(
                      taeGenerationNotification.generationFailed
                    )
                  }
                }
              }),
            { key: taeGenerationNotification.generationFailed, duration: 0 }
          )
        } else {
          errorNotification(
            'Generate T&E activities process failed due to conflicts within sections',
            { key: taeGenerationNotification.generationFailed }
          )
        }
      }

      if (eventData.event_type === 'tae_generator_task_applying_failed_user') {
        markAsDelivered(eventData.context.notification_id)
      }
    }
  })

  const resetDelphi = () => {
    closeDelphiNotifications()
    setDraftActivitySuggesterNotification()
    Modal.destroyAll()
    dispatchDelphiResetEvent()
    dispatchTimetablePreviewResetEvent()
  }

  const closeDelphiNotifications = () => {
    notification.close('suggester-task-succeeded-notification')
    notification.close('suggester-task-failed-notification')
  }

  const setDraftActivitySuggesterValue = (
    event: DraftActivitySuggesterTaskEvent,
    status: DelphiProcessStatus
  ) =>
    setDraftActivitySuggesterNotification({
      activityId: event.activity_id,
      activityName: event.activity_name,
      notificationId: event.context.notification_id,
      startTime: event.start_time,
      status,
      taskId: event.task_id,
      timetableId: event.timetable_id,
      toDay: event.to_day,
      toHour: event.to_hour,
      yearsToRearrange: event.years_to_rearrange
    })
  const setImportConflict = (value: ImportConflict) =>
    (importConflict.value = value)
  const setTimetableImportNotification = (
    value?: TimetableImportNotification
  ) => (timetableImportNotification.value = value)

  const goToTimetableTaskDetails = (event: TimetableImportFinishedEvent) => {
    setTimetableImportNotification()
    router.push({
      name: 'timetable-details',
      params: {
        timetableId: event.timetable_id.toString(),
        rotaId: event.rota_timetable_id?.toString() || ''
      },
      query: { task: event.task_id?.toString() }
    })
    notification.close('import-failed-notification')
  }

  const onWebsocketDisconnect = () => {
    setDraftActivitySuggesterNotification()
    close()
  }

  window.addEventListener('ws-disconnect', onWebsocketDisconnect)

  onUnmounted(() =>
    window.removeEventListener('ws-disconnect', onWebsocketDisconnect)
  )

  return { open }
}
