import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

export const billingReportsChildrenRoutes = [
  {
    path: '',
    name: 'billing-report-list' as const,
    component: () =>
      import('@/views/AdminPanel/BillingReports/BillingReportsView.vue'),
    meta: {
      title: 'Billing reports',
      tab: 'administration',
      permissions: ['billings.view_billingreport']
    }
  },
  {
    path: 'add',
    name: 'add-billing-report' as const,
    component: () =>
      import('@/views/AdminPanel/BillingReports/AddBillingReport/index.vue'),
    meta: {
      title: 'Add billing report',
      tab: 'administration',
      permissions: ['billings.add_billingreport']
    }
  },
  {
    path: ':id',
    name: 'billing-report-details' as const,
    component: () =>
      import('@/views/AdminPanel/BillingReports/BillingReportDetail.vue'),
    meta: {
      title: 'Billing report details',
      tab: 'administration',
      permissions: ['billings.view_billingreport']
    }
  }
] satisfies Route[]

export const billingReportsRoutes = [
  {
    path: 'billing-reports',
    name: 'billing-reports' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Billing reports',
      icon: 'project',
      tab: 'administration'
    },
    redirect: () => getFirstPossibleRoute(billingReportsChildrenRoutes),
    children: billingReportsChildrenRoutes
  }
] satisfies Route[]
