import { type Route } from '@/types/route'

import { getFirstPossibleRoute } from '../utils/getFirstPossibleRoute'

const artisansCoversChildRoutes = [
  {
    path: '',
    name: 'artisans-covers-list' as const,
    component: () =>
      import('@/views/Academics/ArtisansCovers/ArtisansCoversView.vue'),
    meta: {
      title: `Artisans' covers`,
      tab: 'academics'
    }
  },
  {
    path: ':lessonId',
    name: 'artisans-covers-activity' as const,
    component: () => import('@/views/TeacherPanel/TimetableDetails.vue'),
    meta: {
      title: `Artisans' covers`,
      tab: 'academics',
      permissions: ['activities.view_lesson']
    }
  }
] satisfies Route[]

export const artisansCoversRoutes = [
  {
    path: 'covers',
    name: 'artisans-covers' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: `Artisans' covers`,
      tab: 'academics',
      icon: 'swap',
      permissions: ['permissions.view_cover']
    },
    redirect: () => getFirstPossibleRoute(artisansCoversChildRoutes),
    children: artisansCoversChildRoutes
  }
] satisfies Route[]
