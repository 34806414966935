import { type Route } from '@/types/route'

export const teacherRoutes = [
  {
    path: '/teacher',
    name: 'teacher-route' as const,
    redirect: '/my-timetable',
    meta: {
      tab: 'academics'
    },
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        // redirect to new endpoint
        path: 'timetable',
        name: 'teacher-timetable' as const,
        redirect: '/my-timetable',
        meta: {
          tab: 'academics'
        },
        component: () => import('@/views/Layouts/RouterView.vue')
      },
      {
        name: 'ClassDetails' as const,
        path: 'timetable/:lessonId/',
        component: () => import('@/views/TeacherPanel/TimetableDetails.vue'),
        meta: {
          tab: 'academics',
          permissions: ['activities.view_lesson']
        }
      }
    ]
  }
] satisfies Route[]
